@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg2 {
  /* The image used */
  background-image: url("./images/6.png");
  background-position: right 55% top 25%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}
.bg1 {
  /* The image used */
  background-image: url("./images/4.JPG");
  background-position: right 55% top 25%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}

.bg3 {
  /* The image used */
  background-image: url("./images/4.JPG");
  background-position: right 55% top 45%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}
.bg4 {
  /* The image used */
  background-image: url("./images/4.JPG");
  background-position: right 55% top 15%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}

.bg5 {
  /* The image used */
  background-image: url("./images/4.JPG");
  background-position: right 55% top 25%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}